/*
 * Base
 */

/*
Font Scale
*/
@screen xs {
    html {
        font-size: 1.2rem;
    }
}
@screen sm {
    html {
        font-size: 0.9rem;
    }
}
@screen sm {
    html {
        font-size: 1.125rem;
    }
}
@screen md {
    html {
        font-size: 1rem;
    }
}
@screen lg {
    html {
        font-size: 1.125rem;
    }
}
@screen xl {
    html {
        font-size: 1.25rem;
    }
}

/*
Typefaces
*/
body {
    @apply font-prose text-gray-dark subpixel-antialiased;
}
h1,
h2,
h3,
h4,
h5 {
    @apply font-semibold font-title text-navy-light;
}

.blume-wf-active {
    .font-prose,
    body {
        @apply font-prose-wf;
    }
    .font-title,
    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply font-title-wf;
    }
}

/*
Links
*/
a,
a:visited {
    @apply text-inherit no-underline;
}
a {
    @apply no-underline;
}

.underline-links a,
a:hover {
    @apply underline;
}

/*
Text Styles
*/
.title-h1 {
    @apply text-xl;
}
.title-h2 {
    @apply text-3xl leading-tight;
    @apply sm:text-4xl;
}
.title-landing2 {
    @apply text-3xl leading-squeeze tracking-tight;
    @apply sm:text-5xl;
}
.title-detail2 {
    @apply text-4xl leading-squeeze tracking-tight;
    @apply sm:text-6xl;
}
.title-h3 {
    @apply text-xl leading-tight;
    @apply sm:text-3xl;
}
.title-h4 {
    @apply text-lg leading-tight;
    @apply sm:text-xl;
}
.title-alt {
    @apply uppercase font-normal tracking-wide leading-tight;
}
.title-label {
    @apply mb-4 text-xs font-title font-normal uppercase tracking-wide text-gray-medium;
}
.prose-base {
    @apply text-base leading-snug;
    @apply md:text-md md:leading-normal;
}
.prose-body {
    @apply text-md leading-normal;
    @apply md:text-lg md:leading-normal;
}
.prose-lead {
    @apply text-lg leading-normal;
    @apply md:text-xl md:leading-normal;
}
.prose-meta {
    @apply text-sm;
}
::selection {
    @apply bg-blue-fresh;
}

/*
Frills
*/
.quo {
    margin-left: -0.24em;
}
.dquo {
    margin-left: -0.44em;
}
.tquo {
    margin-left: -0.68em;
}

/*
Rich Text
*/
.richtext {
    hr,
    p,
    ul,
    ol {
        @apply mt-0 mb-6;
    }
    hr:last-child,
    p:last-child,
    ul:last-child,
    li:last-child,
    ol:last-child,
    blockquote:last-child {
        @apply mb-0;
    }
    & + & {
        @apply mt-6;
    }

    h2 {
        @apply title-h2 mt-12 sm:mt-16 mb-4 sm:mb-5;
    }
    h3 {
        @apply title-h3 mt-8 sm:mt-12 mb-3;
    }
    h4 {
        @apply title-h4 mt-8 mb-3;
    }
    a:first-child:not([href]) + h2,
    a:first-child:not([href]) + h3,
    a:first-child:not([href]) + h4,
    h2:first-child,
    h3:first-child,
    h4:first-child {
        @apply mt-0;
    }

    ol,
    ul {
        @apply pl-5;
    }
    li {
        @apply mb-2 leading-snug;
    }
    li li {
        @apply ml-2.5;
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style-type: disc;
    }
    li ul {
        list-style-type: circle;
    }
    ol ol {
        list-style-type: lower-alpha;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }

    blockquote {
        @apply border-l-4 border-gray-50-translucent pl-4 sm:pl-6 my-6 sm:my-8 text-base sm:text-md;
        font-style: italic;
    }

    a {
        @apply underline text-blue;
    }
    a.no-underline {
        text-decoration: none;
    }

    hr {
        @apply border-black/15 w-12;
    }

    /* Prevent extra bold fonts in copy-pasted content */
    h1 strong,
    h2 strong,
    h3 strong,
    h4 strong,
    h5 strong,
    h6 strong {
        @apply font-semibold;
    }
}

/* Heading Anchors */
:is(h1, h2, h3, h4, h5, dt) a.anchor {
    @apply absolute;
    width: 1.25em;
    height: 1.25em;
    margin-left: -1.75em;
    margin-left: 0.25em;
    @apply bg-no-repeat bg-center rounded-full opacity-0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244' /%3E%3C/svg%3E");
    background-size: 60% auto;
    &.copied {
        @apply opacity-100;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='green'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E");
    }
    &.copied.fadeOut {
        @apply opacity-0;
    }
    &.copied::after {
        position: absolute;
        text-align: center;
        width: 150%;
        left: -25%;
        top: 115%;
        @apply sm:top-1/2 sm:left-full sm:w-auto sm:-translate-y-1/2 sm:translate-x-[0.75em];
        opacity: 0.5;
        content: ' Copied';
        font-size: 0.5em;
    }
}
:is(h1, h2, h3, h4, h5, dt):hover a.anchor {
    @apply opacity-50;
}
:is(h1, h2, h3, h4, h5, dt):hover a.anchor:hover {
    @apply opacity-100;
    outline-color: rgba(0, 0, 0, 0.1);
    outline-width: 4px;
    text-decoration-color: rgba(51, 46, 25, 0);
    background-color: rgba(0, 0, 0, 0.1);
}
